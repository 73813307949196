import createOpenAPIClient, {
  FetchOptions,
  ParamsOption as ParamsInternal,
  RequestBodyOption as RequestBodyInternal,
} from 'openapi-fetch';
import type {
  FilterKeys,
  PathsWithMethod,
  HttpMethod,
  SuccessResponseJSON,
} from 'openapi-typescript-helpers';
import qs from 'qs';

import { getToken } from '../token';
import config from '../config';

import { paths as AccountingTypes } from './types/Accounting';
import { paths as AuthenticationTypes } from './types/Authentication';
import { paths as ContentTypes } from './types/Content';
import { paths as UsersTypes } from './types/Users';
import { paths as PartnersTypes } from './types/Partners';
import { FetchError } from '../error';
import { chunk, uniq } from 'lodash';

export type { AccountingTypes, AuthenticationTypes, ContentTypes, UsersTypes, PartnersTypes };
export type UseQueryOptions<T> = ParamsInternal<T> &
  RequestBodyInternal<T> & {
    // add your custom options here
    reactQuery: {
      enabled: boolean; // Note: React Query type’s inference is difficult to apply automatically, hence manual option passing here
      // add other React Query options as needed
    };
  };

export type Params<
  Paths extends object,
  P extends keyof Paths,
  M extends HttpMethod,
> = ParamsInternal<M extends keyof Paths[P] ? Paths[P][M] : unknown>['params'];

export type QueryOptional<
  Paths extends object,
  P extends keyof Paths,
  M extends HttpMethod,
> = Extract<Params<Paths, P, M>, { query?: object }>['query'];

export type Query<Paths extends object, P extends keyof Paths, M extends HttpMethod> = Extract<
  QueryOptional<Paths, P, M>,
  object
>;

export type QueryInternal<T> = Extract<
  Extract<ParamsInternal<T>['params'], { query?: object }>['query'],
  object
>;

export type RequestBody<
  Paths extends object,
  P extends keyof Paths,
  M extends HttpMethod,
> = RequestBodyInternal<M extends keyof Paths[P] ? Paths[P][M] : unknown>['body'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ResponseDataInternal<T> = SuccessResponseJSON<T>;

export type ResponseData<
  Paths extends object,
  P extends keyof Paths,
  M extends HttpMethod,
> = ResponseDataInternal<M extends keyof Paths[P] ? Paths[P][M] : unknown>;

// We are missing types for the error response body
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createError(body: any, response: Response) {
  let errorType = 'Server error';
  if (response.status >= 400 && response.status < 500) {
    errorType = 'Client error';
  }

  const message = body ? JSON.stringify(body) : response.statusText;

  const error = new FetchError(
    `${errorType}: ${response.status} ${message}`,
    errorType,
    response.status,
    body,
  );

  return error;
}

// eslint-disable-next-line @typescript-eslint/ban-types
function createClient<Paths extends {}>(clientOptions: Parameters<typeof createOpenAPIClient>[0]) {
  const options = {
    querySerializer: (query: unknown) => {
      return qs.stringify(query, { arrayFormat: 'repeat' });
    },
    // OpenApi fetch defaults to 'application/json'. We need to define bodySerializer in order to set correct headers.
    bodySerializer: (body: unknown) => {
      if (body instanceof FormData) return body;
      return JSON.stringify(body);
    },
  };

  const client = createOpenAPIClient<Paths>({ ...options, ...clientOptions });

  client.use({
    onRequest: async (request) => {
      if (!request.headers.get('Authorization')) {
        request.headers.set('Authorization', `Bearer ${getToken()}`);
      }
      return request;
    },
  });

  return {
    async GET<P extends PathsWithMethod<Paths, 'get'>>(
      url: P,
      init: FetchOptions<FilterKeys<Paths[P], 'get'>>,
    ) {
      const { data, error, response } = await client.GET(url, init);

      if (data) {
        return { data, response };
      }

      throw createError(error, response);
    },

    /**
     * Makes multiple GET requests to the endpoint, splitting the query parameter
     * for the provided key into chunks to make sure the URL doesn't get too long
     * resulting in an error.
     *
     * This will return an object with pages which is an array of all the responses.
     *
     * NOTE: The default limit is 20 (the same as the chunk size) if you expect more than
     * one result per key you need to set the limit to a higher value.
     */
    async GET_CHUNKED<P extends PathsWithMethod<Paths, 'get'>>(
      url: P,
      init: FetchOptions<FilterKeys<Paths[P], 'get'>> & {
        skipCache?: boolean;
        chunkKey: keyof QueryInternal<FilterKeys<Paths[P], 'get'>>;
        limit?: number;
      },
    ) {
      /* The types becomes very messy here since they are all generics with a lot of subtypes,
       * to make it easier to work with we use `any` but
       * all the types are correctly inferred and checked when calling the function
       */
      const CHUNK_SIZE = 15;
      const queryObject =
        init.params && 'query' in init.params && init.params.query ? init.params.query : undefined;

      const ids = queryObject
        ? (queryObject[init.chunkKey as string] as Array<string | number>)
        : [];
      const chunks = chunk(uniq(ids).sort(), CHUNK_SIZE);

      const limit = Math.max(init.limit || 0, CHUNK_SIZE);

      const pages = [];
      for (const chunk of chunks) {
        const { data } = await this.GET<P>(url, {
          ...init,
          params: {
            ...init.params,
            query: { ...queryObject, limit, [init.chunkKey]: chunk },
          },
        });

        pages.push(data);
      }

      /* To make sure the types are correct we need to return
       * each response in the array. We can't for example use flatMap here
       * to combine the results into one array since we don't really know the type
       * and the resulting array will become any[].
       */
      return { pages };
    },

    async PUT<P extends PathsWithMethod<Paths, 'put'>>(
      url: P,
      init: FetchOptions<FilterKeys<Paths[P], 'put'>>,
    ) {
      const { data, error, response } = await client.PUT(url, init);

      if (data) {
        return { data, response };
      }

      throw createError(error, response);
    },

    async POST<P extends PathsWithMethod<Paths, 'post'>>(
      url: P,
      init: FetchOptions<FilterKeys<Paths[P], 'post'>>,
    ) {
      const { data, error, response } = await client.POST(url, init);

      if (data) {
        return { data, response };
      }

      throw createError(error, response);
    },

    async DELETE<P extends PathsWithMethod<Paths, 'delete'>>(
      url: P,
      init: FetchOptions<FilterKeys<Paths[P], 'delete'>>,
    ) {
      const { data, error, response } = await client.DELETE(url, init);

      if (data) {
        return { data, response };
      }

      throw createError(error, response);
    },
  };
}

type RequestOptions = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers?: Record<string, string>;
  body?: string | FormData;
};

async function rawFetch<Paths extends object, P extends keyof Paths, M extends HttpMethod>(
  url: string,
  options?: RequestOptions,
): Promise<{
  data: ResponseData<Paths, P, M>;
  response: Response;
}>;

async function rawFetch<T>(
  url: string,
  options: RequestOptions = {},
): Promise<{ data: T | undefined; response: Response }> {
  const headers: RequestOptions['headers'] = {
    Authorization: `Bearer ${getToken()}`,
    ...options.headers,
  };

  if (options.body && !headers['Content-Type'] && !(options.body instanceof FormData)) {
    headers['Content-Type'] = 'application/json';
  }

  const response = await fetch(url, {
    method: options.method || 'GET',
    headers,
    body: options.body,
    credentials: 'include',
  });

  if (!response.ok) {
    await handleErrors(response);
  }

  if (response.status === 204) {
    return { response, data: undefined };
  }

  const data = await parseData(response);

  return { data, response };

  async function parseData(response: Response) {
    if (response.headers.get('content-type')?.includes('application/json')) {
      return await response.clone().json();
    }

    return await response.clone().text();
  }

  async function handleErrors(response: Response) {
    let json;
    try {
      json = await response.json();
      // eslint-disable-next-line no-empty
    } catch (_) {}

    throw createError(json, response);
  }
}

function createGalaxyClient(apiUrl: string = config.API_URL) {
  return {
    authentication: createClient<AuthenticationTypes>({
      baseUrl: `${apiUrl}authentication`,
    }),
    accounting: createClient<AccountingTypes>({
      baseUrl: `${apiUrl}accounting`,
    }),
    content: createClient<ContentTypes>({
      baseUrl: `${apiUrl}content`,
    }),
    partners: createClient<PartnersTypes>({
      baseUrl: `${apiUrl}partners`,
    }),
    users: createClient<UsersTypes>({
      baseUrl: `${apiUrl}users`,
    }),
    fetch: rawFetch,
  };
}

let clientSingleton: ReturnType<typeof createGalaxyClient>;
let cdnClientSingleton: ReturnType<typeof createGalaxyClient>;

/**
 * NOTE: we need to export the client as a function in this way, contrary to
 * how it's done in galaxy, bosse and alliance, because we need to make sure
 * that the openapi-fetch client is created after the msw server.listen is
 * started in the scenario tests, for the SSR-dependent scenario tests to work
 * as expected.
 *
 * Read more: https://openapi-ts.dev/openapi-fetch/testing#mocking-responses
 */
export function client() {
  if (!clientSingleton) {
    clientSingleton = createGalaxyClient();
  }

  return clientSingleton;
}

/**
 * NOTE: we need to export the cdnClient as a function in this way because we
 * need to make sure that the openapi-fetch client is created after the msw
 * server.listen is started in the scenario tests, for the SSR-dependent scenario
 * tests to work as expected.
 *
 * Read more: https://openapi-ts.dev/openapi-fetch/testing#mocking-responses
 */
export function cdnClient() {
  if (!cdnClientSingleton) {
    cdnClientSingleton = createGalaxyClient(config.CDN_API_URL);
  }

  return cdnClientSingleton;
}
